import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './header.css';
import { Logo } from './components/logo';
import { WithSeparator } from './components/with-separator';
import { useAuth } from './auth-context';
let config = require('../cc-config.json');
function getCurrentMenuLinks(username) {
    return [
        [
            ...(username != undefined ?
                [
                    {
                        to: "/my-account.html",
                        text: `Account (${username})`
                    }
                ] :
                [
                    {
                        to: "/login.html",
                        text: "Log in"
                    },
                    {
                        to: "/sign-up.html",
                        text: "Sign up"
                    }
                ])
        ],
        [
            {
                to: "/view/categories",
                text: "View Maps"
            },
            {
                to: "/edit/categories",
                text: "Edit Maps"
            },
            // {
            //     to: "/data-extracts.html",
            //     text: "Download data"
            // },
            {
                to: "https://github.com/colouring-cities/manual/wiki",
                text: "Colouring Cities Open Manual/Wiki",
                disabled: false,
                external: true
            },
            {
                to: config.githubURL,
                text: "Open code",
                external: true
            },
            {
                to: "https://colouringcities.org/impact-studies",
                text: "Impact Study Showcase",
                external: true
            },
        ],
        [
            {
                to: "https://github.com/colouring-cities/manual/wiki/A.-ABOUT",
                text: "About the Colouring Cities Research Programme",
                external: true
            },
            {
                to: config.manualURL,
                text: "About the Colouring " + config.cityName + " Project",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki/A2.-HOW-TO-GUIDES",
                text: "How to Use",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki/E1.--DATA",
                text: "Data Categories",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki/M3.2-Colouring-Britain:-Who's-Involved%3F",
                text: "Who's Involved?",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki/ETHICAL-FRAMEWORK",
                text: "Ethical Framework",
                external: true
            }
        ],
        [
            {
                to: "/leaderboard.html",
                text: "Top Contributors"
            },
            {
                to: config.githubURL + "/discussions",
                text: "Discussion Forum (GitHub)",
                external: true
            },
            // {
            //     to: "https://discuss.colouring.london/c/blog/9",
            //     text: "Blog",
            //     external: true
            // },
        ],
        [
            {
                to: "https://github.com/colouring-cities/manual/wiki/F2.-PROTOCOLS-&-CODES-OF-CONDUCT#ccrp-contributor-privacy-policy",
                text: "Privacy Policy",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki/F2.-PROTOCOLS-&-CODES-OF-CONDUCT#ccrp-contributor--data-user-data-accuracy--ethical-use-agreement",
                text: "Contributor & Data User Data Accuracy & Ethical Use Agreement",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki/F2.-PROTOCOLS-&-CODES-OF-CONDUCT#ccrp-contributor-code-of-conduct",
                text: "Code of Conduct",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki/F2.-PROTOCOLS-&-CODES-OF-CONDUCT#ccrp-equality-diversity-and-inclusion-policy",
                text: "Equality, Diversity and Inclusion",
                external: true
            },
            {
                to: "https://github.com/colouring-cities/manual/wiki/F2.-PROTOCOLS-&-CODES-OF-CONDUCT#ccrp-protocols-for-international-academic-partners",
                text: "CCRP Academic Partner Protocols",
                external: true
            },
        ],
        [
            {
                to: "/contact.html",
                text: "Contact"
            },
        ],
    ];
}
const Menu = ({ onNavigate }) => {
    const { user } = useAuth();
    const menuLinkSections = getCurrentMenuLinks(user === null || user === void 0 ? void 0 : user.username);
    return (React.createElement(WithSeparator, { separator: React.createElement("hr", null) }, menuLinkSections.map((section, idx) => React.createElement("ul", { key: `menu-section-${idx}`, className: "navbar-nav flex-container" }, section.map(item => (React.createElement("li", { className: 'nav-item', key: `${item.to}-${item.text}` }, item.disabled ?
        React.createElement(LinkStub, { note: item.note }, item.text) :
        item.external ?
            React.createElement(ExternalNavLink, { to: item.to }, item.text) :
            React.createElement(InternalNavLink, { to: item.to, onClick: onNavigate }, item.text))))))));
};
const InternalNavLink = ({ to, onClick, children }) => (React.createElement(NavLink, { className: "nav-link", to: to, onClick: onClick }, children));
const ExternalNavLink = ({ to, children }) => (React.createElement("a", { className: "nav-link", href: to, target: "_blank" }, children));
const LinkStub = ({ note, children }) => (React.createElement("a", { className: "nav-link disabled" },
    children,
    React.createElement("span", { className: "link-note" }, note)));
export const Header = ({ animateLogo }) => {
    const [collapseMenu, setCollapseMenu] = useState(true);
    const toggleCollapse = () => setCollapseMenu(!collapseMenu);
    const handleNavigate = () => setCollapseMenu(true);
    return (React.createElement("header", { className: "main-header navbar navbar-light" },
        React.createElement("div", { className: "nav-header" },
            React.createElement(NavLink, { to: "/" },
                React.createElement(Logo, { variant: animateLogo ? 'animated' : 'default' })),
            React.createElement("button", { className: "navbar-toggler", type: "button", onClick: toggleCollapse, "aria-expanded": !collapseMenu, "aria-label": "Toggle navigation" },
                "Menu\u00A0",
                collapseMenu ?
                    React.createElement("span", { className: "navbar-toggler-icon" })
                    : React.createElement("span", { className: "close" }, "\u00D7"))),
        React.createElement("nav", { className: collapseMenu ? 'collapse navbar-collapse' : 'navbar-collapse' },
            React.createElement(Menu, { onNavigate: handleNavigate }))));
};
