import React, { createContext, useCallback, useContext, useState } from 'react';
const stub = () => {
    throw new Error('DisplayPreferencesProvider not set up');
};
export const DisplayPreferencesContext = createContext({
    showOverlayList: stub,
    resetLayersAndHideTheirList: stub,
    vista: undefined,
    vistaSwitch: stub,
    vistaSwitchOnClick: undefined,
    flood: undefined,
    floodSwitch: stub,
    floodSwitchOnClick: undefined,
    creative: undefined,
    creativeSwitch: stub,
    creativeSwitchOnClick: undefined,
    housing: undefined,
    housingSwitch: stub,
    housingSwitchOnClick: undefined,
    conservation: undefined,
    conservationSwitch: stub,
    conservationSwitchOnClick: undefined,
    parcel: undefined,
    parcelSwitch: stub,
    parcelSwitchOnClick: undefined,
    borough: undefined,
    boroughSwitch: stub,
    boroughSwitchOnClick: undefined,
    historicData: undefined,
    historicDataSwitch: stub,
    historicDataSwitchOnClick: undefined,
    historicMap: undefined,
    historicMapSwitch: stub,
    historicMapSwitchOnClick: undefined,
    editableBuildings: undefined,
    editableBuildingsSwitch: stub,
    editableBuildingsSwitchOnClick: undefined,
    darkLightTheme: undefined,
    darkLightThemeSwitch: stub,
    darkLightThemeSwitchOnClick: undefined,
    showLayerSelection: undefined,
    showLayerSelectionSwitch: stub,
    showLayerSelectionSwitchOnClick: undefined,
});
const noop = () => { };
export const DisplayPreferencesProvider = ({ children }) => {
    const defaultVista = 'disabled';
    const defaultFlood = 'disabled';
    const defaultCreative = 'disabled';
    const defaultHousing = 'disabled';
    const defaultBorough = 'disabled';
    const defaultParcel = 'disabled';
    const defaultConservation = 'disabled';
    const defaultHistoricData = 'disabled';
    const defaultHistoricMap = 'disabled';
    const defaultEditableBuildings = 'enabled';
    const defaultShowLayerSelection = 'disabled';
    const [vista, setVista] = useState(defaultVista);
    const [flood, setFlood] = useState(defaultFlood);
    const [creative, setCreative] = useState(defaultCreative);
    const [housing, setHousing] = useState(defaultHousing);
    const [borough, setBorough] = useState(defaultBorough);
    const [parcel, setParcel] = useState(defaultParcel);
    const [conservation, setConservation] = useState(defaultConservation);
    const [historicData, setHistoricData] = useState(defaultHistoricData);
    const [historicMap, setHistoricMap] = useState(defaultHistoricMap);
    const [editableBuildings, setEditableBuildings] = useState(defaultEditableBuildings);
    const [darkLightTheme, setDarkLightTheme] = useState('night');
    const [showLayerSelection, setShowLayerSelection] = useState(defaultShowLayerSelection);
    const showOverlayList = useCallback((e) => {
        setShowLayerSelection('enabled');
    }, []);
    const resetLayersAndHideTheirList = useCallback((e) => {
        setVista(defaultVista);
        setFlood(defaultFlood);
        setCreative(defaultCreative);
        setHousing(defaultHousing);
        setBorough(defaultBorough);
        setParcel(defaultParcel);
        setConservation(defaultConservation);
        setHistoricData(defaultHistoricData);
        setHistoricMap(defaultHistoricMap);
        setEditableBuildings(defaultEditableBuildings);
        setShowLayerSelection(defaultShowLayerSelection); // reset layers + hiding this panel is integrated into one action
        //setDarkLightTheme('night'); // reset only layers
    }, []);
    function anyLayerModifiedState() {
        if (vista != defaultVista) {
            return true;
        }
        if (flood != defaultFlood) {
            return true;
        }
        if (creative != defaultCreative) {
            return true;
        }
        if (housing != defaultHousing) {
            return true;
        }
        if (borough != defaultBorough) {
            return true;
        }
        if (parcel != defaultParcel) {
            return true;
        }
        if (conservation != defaultConservation) {
            return true;
        }
        if (historicData != defaultHistoricData) {
            return true;
        }
        if (historicMap != defaultHistoricMap) {
            return true;
        }
        if (editableBuildings != defaultEditableBuildings) {
            return true;
        }
        setEditableBuildings;
        //darkLightTheme not handled here
        return false;
    }
    const vistaSwitch = useCallback((e) => {
        e.preventDefault();
        const newVista = (vista === 'enabled') ? 'disabled' : 'enabled';
        setVista(newVista);
    }, [vista]);
    const vistaSwitchOnClick = (e) => {
        e.preventDefault();
        const newVista = (vista === 'enabled') ? 'disabled' : 'enabled';
        setVista(newVista);
    };
    const floodSwitch = useCallback((e) => {
        e.preventDefault();
        const newFlood = (flood === 'enabled') ? 'disabled' : 'enabled';
        setFlood(newFlood);
    }, [flood]);
    const floodSwitchOnClick = (e) => {
        e.preventDefault();
        const newFlood = (flood === 'enabled') ? 'disabled' : 'enabled';
        setFlood(newFlood);
    };
    const housingSwitch = useCallback((e) => {
        e.preventDefault();
        const newHousing = (housing === 'enabled') ? 'disabled' : 'enabled';
        setHousing(newHousing);
    }, [housing]);
    const housingSwitchOnClick = (e) => {
        e.preventDefault();
        const newHousing = (housing === 'enabled') ? 'disabled' : 'enabled';
        setHousing(newHousing);
    };
    const creativeSwitch = useCallback((e) => {
        e.preventDefault();
        const newCreative = (creative === 'enabled') ? 'disabled' : 'enabled';
        setCreative(newCreative);
    }, [creative]);
    const creativeSwitchOnClick = (e) => {
        e.preventDefault();
        const newCreative = (creative === 'enabled') ? 'disabled' : 'enabled';
        setCreative(newCreative);
    };
    const boroughSwitch = useCallback((e) => {
        flipBorough(e);
    }, [borough]);
    const boroughSwitchOnClick = (e) => {
        flipBorough(e);
    };
    function flipBorough(e) {
        e.preventDefault();
        const newBorough = (borough === 'enabled') ? 'disabled' : 'enabled';
        setBorough(newBorough);
    }
    const parcelSwitch = useCallback((e) => {
        flipParcel(e);
    }, [parcel]);
    const parcelSwitchOnClick = (e) => {
        flipParcel(e);
    };
    function flipParcel(e) {
        e.preventDefault();
        const newParcel = (parcel === 'enabled') ? 'disabled' : 'enabled';
        setParcel(newParcel);
    }
    const conservationSwitch = useCallback((e) => {
        flipConservation(e);
    }, [conservation]);
    const conservationSwitchOnClick = (e) => {
        flipConservation(e);
    };
    function flipConservation(e) {
        e.preventDefault();
        const newConservation = (conservation === 'enabled') ? 'disabled' : 'enabled';
        setConservation(newConservation);
    }
    const historicDataSwitch = useCallback((e) => {
        if (historicMap === 'enabled') {
            fliphistoricMap(e);
        }
        flipHistoricData(e);
    }, [historicData, historicMap]);
    const historicDataSwitchOnClick = (e) => {
        flipHistoricData(e);
    };
    function flipHistoricData(e) {
        e.preventDefault();
        const newHistoric = (historicData === 'enabled') ? 'disabled' : 'enabled';
        setHistoricData(newHistoric);
    }
    const historicMapSwitch = useCallback((e) => {
        if (historicData === 'enabled') {
            flipHistoricData(e);
        }
        fliphistoricMap(e);
    }, [historicMap, historicData]);
    const historicMapSwitchOnClick = (e) => {
        fliphistoricMap(e);
    };
    function fliphistoricMap(e) {
        e.preventDefault();
        const newHistoric = (historicMap === 'enabled') ? 'disabled' : 'enabled';
        setHistoricMap(newHistoric);
    }
    const editableBuildingsSwitch = useCallback((e) => {
        flipEditableBuildings(e);
    }, [editableBuildings]);
    const editableBuildingsSwitchOnClick = (e) => {
        flipEditableBuildings(e);
    };
    function flipEditableBuildings(e) {
        e.preventDefault();
        const newValue = (editableBuildings === 'enabled') ? 'disabled' : 'enabled';
        setEditableBuildings(newValue);
    }
    const darkLightThemeSwitch = useCallback((e) => {
        flipDarkLightTheme(e);
    }, [darkLightTheme]);
    const darkLightThemeSwitchOnClick = (e) => {
        flipDarkLightTheme(e);
    };
    function flipDarkLightTheme(e) {
        e.preventDefault();
        const newDarkLightTheme = (darkLightTheme === 'light') ? 'night' : 'light';
        setDarkLightTheme(newDarkLightTheme);
    }
    const showLayerSelectionSwitch = useCallback((e) => {
        flipShowLayerSelection(e);
    }, [showLayerSelection]);
    const showLayerSelectionSwitchOnClick = (e) => {
        flipShowLayerSelection(e);
    };
    function flipShowLayerSelection(e) {
        e.preventDefault();
        const newShowLayerSelection = (showLayerSelection === 'enabled') ? 'disabled' : 'enabled';
        setShowLayerSelection(newShowLayerSelection);
    }
    return (React.createElement(DisplayPreferencesContext.Provider, { value: {
            showOverlayList,
            resetLayersAndHideTheirList,
            vista,
            vistaSwitch,
            vistaSwitchOnClick,
            flood,
            floodSwitch,
            floodSwitchOnClick,
            creative,
            creativeSwitch,
            creativeSwitchOnClick,
            housing,
            housingSwitch,
            housingSwitchOnClick,
            conservation,
            conservationSwitch,
            conservationSwitchOnClick,
            parcel,
            parcelSwitch,
            parcelSwitchOnClick,
            borough,
            boroughSwitch,
            boroughSwitchOnClick,
            historicData,
            historicDataSwitch,
            historicDataSwitchOnClick,
            historicMap,
            historicMapSwitch,
            historicMapSwitchOnClick,
            editableBuildings,
            editableBuildingsSwitch,
            editableBuildingsSwitchOnClick,
            darkLightTheme,
            darkLightThemeSwitch,
            darkLightThemeSwitchOnClick,
            showLayerSelection,
            showLayerSelectionSwitch,
            showLayerSelectionSwitchOnClick
        } }, children));
};
export const useDisplayPreferences = () => {
    return useContext(DisplayPreferencesContext);
};
