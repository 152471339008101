import React, { Fragment } from 'react';
import { commonSourceTypes, dataFields } from '../../config/data-fields-config';
import DataEntry from '../data-components/data-entry';
import SelectDataEntry from '../data-components/select-data-entry';
import withCopyEdit from '../data-container';
import Verification from '../data-components/verification';
import InfoBox from '../../components/info-box';
import { DataEntryGroup } from '../data-components/data-entry-group';
import { MultiDataEntry } from '../data-components/multi-data-entry/multi-data-entry';
import { LogicalDataEntry } from '../data-components/logical-data-entry/logical-data-entry';
import NumericDataEntry from '../data-components/numeric-data-entry';
import { useDisplayPreferences } from '../../displayPreferences-context';
import { DynamicsBuildingPane, DynamicsDataEntry } from './dynamics/dynamics-data-entry';
import { FieldRow } from '../data-components/field-row';
/**
* Age & History view/edit section
*/
const AgeHistoryView = (props) => {
    var _a, _b, _c, _d;
    const currentYear = new Date().getFullYear();
    const building = props.building;
    const thisYear = (new Date()).getFullYear();
    const currentBuildingConstructionYear = building.date_year || undefined;
    const { historicData, historicDataSwitchOnClick, darkLightTheme } = useDisplayPreferences();
    const { historicMap, historicMapSwitchOnClick } = useDisplayPreferences();
    const switchToSurvivalMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('survival_status');
        historicMapSwitchOnClick(e);
        if (historicData === 'enabled') {
            historicDataSwitchOnClick(e);
        }
    };
    const switchToSurvivalDataStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('survival_status');
        historicDataSwitchOnClick(e);
        if (historicMap === 'enabled') {
            historicMapSwitchOnClick(e);
        }
    };
    const switchToStylePeriodMapStyle = (e) => {
        e.preventDefault();
        props.onMapColourScale('typology_style_period');
    };
    const switchToAgeMapStyle = (event) => {
        switchToMapStyleHideHistoricMaps(event, 'date_year');
    };
    const switchToAgeCladdingMapStyle = (event) => {
        switchToMapStyleHideHistoricMaps(event, 'cladding_year');
    };
    const switchToAgeExtensionMapStyle = (event) => {
        switchToMapStyleHideHistoricMaps(event, 'extension_year');
    };
    const switchToAgeRetrofitMapStyle = (event) => {
        switchToMapStyleHideHistoricMaps(event, 'retrofit_year');
    };
    const switchToMapStyleHideHistoricMaps = (event, map_style) => {
        event.preventDefault();
        if (historicData === 'enabled') {
            historicDataSwitchOnClick(event);
        }
        if (historicMap === 'enabled') {
            historicMapSwitchOnClick(event);
        }
        props.onMapColourScale(map_style);
    };
    let construction_length = null;
    if (props.building.date_year != null && props.building.date_year_completed != null) {
        construction_length = props.building.date_year_completed - props.building.date_year;
        construction_length = Math.max(construction_length, 1);
    }
    const queryParameters = new URLSearchParams(window.location.search);
    const subcat = queryParameters.get("sc");
    return (React.createElement(Fragment, null,
        React.createElement(DataEntryGroup, { name: "Architectural Style/Historical Period", collapsed: subcat == null || subcat != "2" },
            (props.mapColourScale != "typology_style_period") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToStylePeriodMapStyle }, "Click to show architectural style.")
                :
                    React.createElement(React.Fragment, null),
            React.createElement(SelectDataEntry, { title: dataFields.typology_style_period.title, slug: "typology_style_period", value: props.building.typology_style_period, tooltip: dataFields.typology_style_period.tooltip, options: dataFields.typology_style_period.items, mode: props.mode, copy: props.copy, onChange: props.onChange }),
            React.createElement(Verification, { slug: "typology_style_period", allow_verify: props.user !== undefined && props.building.typology_style_period !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("typology_style_period"), user_verified_as: props.user_verified.typology_style_period, verified_count: props.building.verified.typology_style_period }),
            React.createElement(SelectDataEntry, { title: dataFields.typology_style_period_source_type.title, slug: "typology_style_period_source_type", value: props.building.typology_style_period_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.typology_style_period_source_type.tooltip, placeholder: dataFields.typology_style_period_source_type.example, options: dataFields.typology_style_period_source_type.items }),
            (props.building.typology_style_period_source_type == commonSourceTypes[0] ||
                props.building.typology_style_period_source_type == commonSourceTypes[1] ||
                props.building.typology_style_period_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.typology_style_period_source_links.title, slug: "typology_style_period_source_links", value: props.building.typology_style_period_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.typology_style_period_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Building Age/Construction Date", collapsed: subcat == null || subcat != "1" },
            (props.mapColourScale != "date_year") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToAgeMapStyle }, "Click to show building age.")
                :
                    React.createElement(React.Fragment, null),
            React.createElement(NumericDataEntry, { title: dataFields.date_year.title, slug: "date_year", value: props.building.date_year, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: props.building.date_year_completed, tooltip: dataFields.date_year.tooltip }),
            React.createElement(Verification, { slug: "date_year", allow_verify: props.user !== undefined && props.building.date_year !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("date_year"), user_verified_as: props.user_verified.date_year, verified_count: props.building.verified.date_year }),
            React.createElement(NumericDataEntry, { title: dataFields.date_year_completed.title, slug: "date_year_completed", value: props.building.date_year_completed, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: props.building.date_year, max: currentYear, tooltip: dataFields.date_year_completed.tooltip }),
            React.createElement(Verification, { slug: "date_year_completed", allow_verify: props.user !== undefined && props.building.date_year_completed !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("date_year_completed"), user_verified_as: props.user_verified.date_year_completed, verified_count: props.building.verified.date_year_completed }),
            React.createElement(NumericDataEntry, { title: "Estimated duration of construction (years)", slug: "size_total_floors", value: construction_length, mode: props.mode, copy: props.copy, tooltip: "Length of building construction (calculated from above values).", onChange: props.onChange, step: 1, min: 0, disabled: true }),
            React.createElement("hr", null),
            React.createElement(NumericDataEntry, { title: dataFields.facade_year.title, slug: "facade_year", value: props.building.facade_year, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.facade_year.tooltip }),
            React.createElement(Verification, { slug: "facade_year", allow_verify: props.user !== undefined && props.building.facade_year !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("facade_year"), user_verified_as: props.user_verified.facade_year, verified_count: props.building.verified.facade_year }),
            React.createElement("hr", null),
            React.createElement(SelectDataEntry, { title: dataFields.date_source_type.title, slug: "date_source_type", value: props.building.date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.date_source_type.tooltip, options: dataFields.date_source_type.items, placeholder: dataFields.date_source_type.example }),
            (props.building.date_source_type == dataFields.date_source_type.items[0] ||
                props.building.date_source_type == dataFields.date_source_type.items[1] ||
                props.building.date_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.date_source_links.title, slug: "date_source_links", value: props.building.date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Cladding, Extensions & Retrofits", collapsed: subcat == null || subcat != "3" },
            (props.mapColourScale != "cladding_year") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToAgeCladdingMapStyle }, "Click to show cladding date.")
                :
                    React.createElement(React.Fragment, null),
            React.createElement(NumericDataEntry, { slug: 'age_cladding_date', title: dataFields.age_cladding_date.title, value: props.building.age_cladding_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.age_cladding_date.tooltip }),
            React.createElement(Verification, { slug: "age_cladding_date", allow_verify: props.user !== undefined && props.building.age_cladding_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_cladding_date"), user_verified_as: props.user_verified.age_cladding_date, verified_count: props.building.verified.age_cladding_date }),
            React.createElement(SelectDataEntry, { title: dataFields.age_cladding_date_source_type.title, slug: "age_cladding_date_source_type", value: props.building.age_cladding_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_cladding_date_source_type.tooltip, options: dataFields.age_cladding_date_source_type.items, placeholder: dataFields.age_cladding_date_source_type.example }),
            (props.building.age_cladding_date_source_type == dataFields.age_cladding_date_source_type.items[0] ||
                props.building.age_cladding_date_source_type == dataFields.age_cladding_date_source_type.items[1] ||
                props.building.age_cladding_date_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.age_cladding_date_source_links.title, slug: "age_cladding_date_source_links", value: props.building.age_cladding_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_cladding_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
            React.createElement("hr", null),
            (props.mapColourScale != "extension_year") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToAgeExtensionMapStyle }, "Click to show extension date.")
                :
                    React.createElement(React.Fragment, null),
            React.createElement(NumericDataEntry, { slug: 'age_extension_date', title: dataFields.age_extension_date.title, value: props.building.age_extension_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.age_extension_date.tooltip }),
            React.createElement(Verification, { slug: "age_extension_date", allow_verify: props.user !== undefined && props.building.age_extension_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_extension_date"), user_verified_as: props.user_verified.age_extension_date, verified_count: props.building.verified.age_extension_date }),
            React.createElement(SelectDataEntry, { title: dataFields.age_extension_date_source_type.title, slug: "age_extension_date_source_type", value: props.building.age_extension_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_extension_date_source_type.tooltip, options: dataFields.age_extension_date_source_type.items, placeholder: dataFields.age_extension_date_source_type.example }),
            (props.building.age_extension_date_source_type == dataFields.age_extension_date_source_type.items[0] ||
                props.building.age_extension_date_source_type == dataFields.age_extension_date_source_type.items[1] ||
                props.building.age_extension_date_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.age_extension_date_source_links.title, slug: "age_extension_date_source_links", value: props.building.age_extension_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_extension_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true })),
            React.createElement("hr", null),
            (props.mapColourScale != "retrofit_year") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark key-button`, onClick: switchToAgeRetrofitMapStyle }, "Click to show retrofit date.")
                :
                    React.createElement(React.Fragment, null),
            React.createElement(NumericDataEntry, { slug: 'age_retrofit_date', title: dataFields.age_retrofit_date.title, value: props.building.age_retrofit_date, mode: props.mode, copy: props.copy, onChange: props.onChange, step: 1, min: 1, max: currentYear, tooltip: dataFields.age_retrofit_date.tooltip }),
            React.createElement(Verification, { slug: "age_retrofit_date", allow_verify: props.user !== undefined && props.building.age_retrofit_date !== null && !props.edited, onVerify: props.onVerify, user_verified: props.user_verified.hasOwnProperty("age_retrofit_date"), user_verified_as: props.user_verified.age_retrofit_date, verified_count: props.building.verified.age_retrofit_date }),
            React.createElement(SelectDataEntry, { title: dataFields.age_retrofit_date_source_type.title, slug: "age_retrofit_date_source_type", value: props.building.age_retrofit_date_source_type, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_retrofit_date_source_type.tooltip, options: dataFields.age_retrofit_date_source_type.items, placeholder: dataFields.age_retrofit_date_source_type.example }),
            (props.building.age_retrofit_date_source_type == dataFields.age_retrofit_date_source_type.items[0] ||
                props.building.age_retrofit_date_source_type == dataFields.age_retrofit_date_source_type.items[1] ||
                props.building.age_retrofit_date_source_type == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.age_retrofit_date_source_links.title, slug: "age_retrofit_date_source_links", value: props.building.age_retrofit_date_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_retrofit_date_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Lifespan & Site History", collapsed: subcat == null || subcat != "4" },
            React.createElement(DataEntryGroup, { name: "Constructions & Demolitions on this Site", collapsed: subcat == null || subcat != "4" },
                React.createElement(DynamicsBuildingPane, null,
                    React.createElement("label", null, "Current building (building age data editable above)"),
                    React.createElement(FieldRow, null,
                        React.createElement("div", null,
                            React.createElement(NumericDataEntry, { slug: '', title: dataFields.demolished_buildings.items.year_constructed.title, value: currentBuildingConstructionYear, disabled: true, mode: 'view' })),
                        React.createElement("div", null,
                            React.createElement(NumericDataEntry, { slug: '', title: dataFields.demolished_buildings.items.year_demolished.title, value: undefined, placeholder: '---', disabled: true, mode: 'view' })),
                        React.createElement("div", { style: { flex: '0 1 27%' } },
                            React.createElement(DataEntry, { slug: '', title: 'Lifespan to date', value: (thisYear - currentBuildingConstructionYear) + '', disabled: true, mode: 'view' })))),
                currentBuildingConstructionYear == undefined ?
                    React.createElement(InfoBox, null, "To add historical records, fill in the building age data (above) first.") :
                    React.createElement(React.Fragment, null,
                        React.createElement(LogicalDataEntry, { slug: 'dynamics_has_demolished_buildings', title: dataFields.dynamics_has_demolished_buildings.title, value: building.dynamics_has_demolished_buildings, disallowFalse: ((_b = (_a = building.demolished_buildings) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0, disallowNull: ((_d = (_c = building.demolished_buildings) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0) > 0, onChange: props.onSaveChange, mode: props.mode, copy: props.copy }),
                        building.dynamics_has_demolished_buildings &&
                            React.createElement(React.Fragment, null,
                                React.createElement(DynamicsDataEntry
                                /*
                                    Will clear the edits and new record data upon navigating to another building.
                                    Should get a better way to do this, plus a way to actually keep unsaved edits.
                                */
                                , { 
                                    /*
                                        Will clear the edits and new record data upon navigating to another building.
                                        Should get a better way to do this, plus a way to actually keep unsaved edits.
                                    */
                                    key: building.building_id, value: building.demolished_buildings, editableEntries: true, slug: 'demolished_buildings', title: dataFields.demolished_buildings.title, mode: props.mode, onChange: props.onChange, onSaveAdd: props.onSaveAdd, hasEdits: props.edited, maxYear: currentBuildingConstructionYear, minYear: 50 }),
                                props.mode === 'view' &&
                                    React.createElement(InfoBox, null, "Switch to edit mode to add/edit past building records"))))),
        React.createElement(DataEntryGroup, { name: "Survival Tracking", collapsed: subcat == null || subcat != "5" },
            React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                React.createElement("i", null, "Can you help us create a map that shows how many buildings in this area have survived since the 1890s? Choose a colour to indicate whether the building has survived.")),
            (historicMap === "enabled") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToAgeMapStyle }, "Click to hide the 1890s OS historical map.")
                :
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToSurvivalMapStyle }, "Click to show the 1890s OS historical map."),
            (historicData === "enabled") ?
                React.createElement("button", { className: `map-switcher-inline enabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToAgeMapStyle }, "Click to hide the 1890s OS historical map with modern footprints.")
                :
                    React.createElement("button", { className: `map-switcher-inline disabled-state btn btn-outline btn-outline-dark ${darkLightTheme}`, onClick: switchToSurvivalDataStyle }, "Click to show the 1890s OS historical map with modern footprints."),
            React.createElement(SelectDataEntry, { title: dataFields.survival_status.title, slug: "survival_status", value: props.building.survival_status, tooltip: dataFields.survival_status.tooltip, options: dataFields.survival_status.items, mode: props.mode, copy: props.copy, onChange: props.onChange }),
            React.createElement(SelectDataEntry, { title: dataFields.survival_source.title, slug: "survival_source", value: props.building.survival_source, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.survival_source.tooltip, placeholder: dataFields.survival_source.example, options: dataFields.survival_source.items }),
            (props.building.survival_source == dataFields.survival_source.items[0] ||
                props.building.survival_source == dataFields.survival_source.items[1] ||
                props.building.survival_source == null) ? React.createElement(React.Fragment, null) :
                React.createElement(React.Fragment, null,
                    React.createElement(MultiDataEntry, { title: dataFields.survival_source_links.title, slug: "survival_source_links", value: props.building.survival_source_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.survival_source_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))),
        React.createElement(DataEntryGroup, { name: "Historical Map Data Options", collapsed: subcat == null || subcat != "6" },
            React.createElement(InfoBox, { type: 'warning' }, "This section is under development"),
            React.createElement("div", { className: `alert alert-dark`, role: "alert", style: { fontSize: 13, backgroundColor: "#f6f8f9" } },
                React.createElement("i", null, "This section provides links to open digitised historical maps/mapping data that we are using in the Colouring Cities platform.")),
            React.createElement(MultiDataEntry, { title: dataFields.age_historical_raster_map_links.title, slug: "age_historical_raster_map_links", value: props.building.age_historical_raster_map_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_historical_raster_map_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }),
            React.createElement(MultiDataEntry, { title: dataFields.age_historical_vectorised_footprint_links.title, slug: "age_historical_vectorised_footprint_links", value: props.building.age_historical_vectorised_footprint_links, mode: props.mode, copy: props.copy, onChange: props.onChange, tooltip: dataFields.age_historical_vectorised_footprint_links.tooltip, placeholder: "https://...", editableEntries: true, isUrl: true }))));
};
const AgeHistoryContainer = withCopyEdit(AgeHistoryView);
export default AgeHistoryContainer;
